import { UserContext } from '../../context/user-context'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './login.scss'
import { Loader } from '../../components'
import { mailSendAtom } from '../../services/atoms'
import { API_HEADERS, fetchApi } from '../../services/fetchApi'
import { useAtom } from 'jotai'
import { Eye, EyeOff } from 'react-feather'
import { useMutation } from 'react-query'

const Login = () => {
  const { user, setUser } = useContext(UserContext)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [, setMailSend] = useAtom(mailSendAtom)

  const navigate = useNavigate()
  const { mutate, isLoading } = useMutation({
    mutationFn: (data: { email: string; password: string }) =>
      fetchApi('user/sign-in', {
        method: 'POST',
        body: data,
      }),
  })

  useEffect(() => {
    if (user.userId) {
      navigate('/', {})
    }
  }, [user, navigate])

  const loginUser = () => {
    mutate(
      { email, password },
      {
        onSuccess: (res) => {
          localStorage.setItem('user', JSON.stringify({ userId: res.data.userId, email: res.data.email }))
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('refreshToken', res.data.refreshToken)
          API_HEADERS.Authorization = `Bearer ${res.data.token}`
          API_HEADERS.RefreshToken = `Bearer ${res.data.refreshToken}`
          setUser(res.data)
        },
        onError: (err: any) => {
          if (err.response && err.response.status === 302) {
            setMailSend(true)
            navigate('/forgot-password')
          }
        },
      },
    )
  }

  return (
    <div data-container="login-container" className="form-container">
      <h6>To manage your plan, sign in using the account you created at the time of purchase.</h6>
      <form>
        <input
          type="email"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void loginUser()
            }
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          name="email"
          placeholder="Email"
          aria-label="Login"
          autoComplete="email"
          required
        />
        <div>
          <input
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                void loginUser()
              }
            }}
            value={password}
            name="password"
            placeholder="Password"
            aria-label="Password"
            autoComplete="password"
            required
            type={showPassword ? 'text' : 'password'}
          />
          {showPassword ? (
            <Eye onClick={() => setShowPassword(false)} />
          ) : (
            <EyeOff onClick={() => setShowPassword(true)} />
          )}
        </div>
        <Link to="/forgot-password">Forgot Password?</Link>
        {isLoading ? (
          <Loader />
        ) : (
          <button disabled={!email || !password} type="submit" onClick={loginUser}>
            Sign In
          </button>
        )}
      </form>
    </div>
  )
}

export default Login
