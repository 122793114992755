import { FC } from 'react'

interface Props {
  cardType: string
  last4: string
  handleModalChange: () => void
  paypal: string
}

const BillingInfo: FC<Props> = ({ cardType, last4, handleModalChange, paypal }) => {
  function navigateToPaypal() {
    window.open('https://www.paypal.com/signin', '_blank')
  }
  return (
    <article data-container="billing-info">
      <span>Billing information</span>
      <p>{paypal ? `Paypal Id: ${paypal}` : `${cardType} ending in ${last4}`}</p>
      <button onClick={!paypal ? handleModalChange : navigateToPaypal} className="outline">
        {!paypal ? 'Edit Billing Information' : 'Go To Paypal Account'}
      </button>
    </article>
  )
}

export default BillingInfo
