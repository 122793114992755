import React, { useContext } from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import './app.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import logo from './assets/images/logo.png'
import { PaymentSuccess } from './components'
import { UserContext } from './context/user-context'
import { ForgotPassword, Login, Profile, ResetPassword, Subscription } from './modules'
import { hasRecurlyProfileAtom } from './services/atoms'
import { fetchApi } from './services/fetchApi'
import { useAtom } from 'jotai'
import { useQuery } from 'react-query'

const App = () => {
  const { user, setUser } = useContext(UserContext)
  const [, setHasRecurlyProfile] = useAtom(hasRecurlyProfileAtom)
  const navigate = useNavigate()
  const token = `Bearer ${localStorage.getItem('token')}`
  const refreshToken = localStorage.getItem('refreshToken')
  useQuery(
    'tokenCheck',
    () => fetchApi('check-session', { headers: { Authorization: token, RefreshToken: refreshToken as string } }),
    {
      retry: false,
      onSuccess: (result) => {
        if (result.token) {
          localStorage.setItem('token', result.token)
        }
      },
      onError: () => {
        logout()
        navigate('/login')
      },
      enabled: !!localStorage.getItem('token'),
    },
  )

  const logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setUser({ userId: '', email: '' })
    setHasRecurlyProfile(true)
  }

  return (
    <div className="container">
      <header>
        <div>
          <img onClick={() => navigate('')} src={logo} alt="logo" />
          <p onClick={() => navigate('')} className="email-mobile">
            {user.email}
          </p>
        </div>
        {user.userId && (
          <div className="flex align-center">
            <p onClick={() => navigate('')} className="email-web">
              {user.email}
            </p>
            <button onClick={logout}>Logout</button>
          </div>
        )}
      </header>
      <section>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Profile />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
          </Route>
        </Routes>
      </section>
    </div>
  )
}

function RequireAuth() {
  const { user } = useContext(UserContext)

  if (!user.userId) {
    return <Navigate to="/login" />
  }

  return <Outlet />
}

export default App
