import React, { useState } from 'react'
import { X, CheckCircle } from 'react-feather'
import './styles.scss'
import { userNameAtom } from '../../services/atoms'
import { fetchApi } from '../../services/fetchApi'
import { validatePassword } from '../../utils/validators'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState<{ [key: string]: boolean } | null>(null)
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const code = searchParams.get('code')
  const [userName, setUserName] = useAtom(userNameAtom)

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: { password: string; code: string; userName: string }) =>
      fetchApi('user/confirm-reset-password', {
        method: 'POST',
        body: data,
      }),
    onSuccess: (res) => {
      alert('Password successfully reset.')
      setUserName('')
      navigate('/login')
    },
  })

  return (
    <div data-container="reset-password" className="form-container">
      <h5>Enter your new password</h5>
      <input
        type="password"
        value={password}
        onChange={(e) => {
          const errors = validatePassword(e.target.value)
          setPasswordErrors(errors)
          setPassword(e.target.value)
        }}
        placeholder="New Password"
      />
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirm Password"
      />
      <button
        disabled={isLoading}
        onClick={() => {
          if (password !== confirmPassword) {
            setPasswordMatch(false)
          } else {
            setPasswordMatch(true)
            mutate({ password, code: code || '', userName })
          }
        }}
      >
        Change Password
      </button>
      <aside>
        <nav>
          <ul>
            <li>
              {passwordErrors?.lowercaseValid ? <CheckCircle color="green" /> : <X color="red" />}
              Password must contain a lower case letter
            </li>
            <li>
              {passwordErrors?.uppercaseValid ? <CheckCircle color="green" /> : <X color="red" />}
              Password must contain an upper case letter
            </li>
            <li>
              {passwordErrors?.numberValid ? <CheckCircle color="green" /> : <X color="red" />}
              Password must contain a number
            </li>
            <li>
              {passwordErrors?.lengthValid ? <CheckCircle color="green" /> : <X color="red" />}
              Password must contain at least 8 characters
            </li>
            {!passwordMatch && (
              <li>
                <X color="red" />
                Passwords doesn't match
              </li>
            )}
          </ul>
        </nav>
      </aside>
    </div>
  )
}

export default ResetPassword
