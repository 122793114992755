import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  useRecurly,
  IndividualElementChangeEvent,
} from '@recurly/react-recurly'
import { ChangeEvent, FC, FormEvent, useRef, useState } from 'react'
import './reculry-form.scss'
import { hasRecurlyProfileAtom } from '../../services/atoms'
import { useMutationsHook } from '../../services/mutations'
import { STATES } from '../../utils/constants'
import { Loader } from '../index'
import clsx from 'clsx'
import { useAtom } from 'jotai'

export interface FormData extends Record<string, string> {
  firstName: string
  lastName: string
  address1: string
  address2: string
  city: string
  state: string
  zipCode: string
  country: string
}

interface FormErrors {
  [key: string]: string
}

interface Props {
  handleModalClose: () => void
  handleCreateRecurlyAccount?: (token: string, data?: FormData) => void
}

const RecurlyForm: FC<Props> = ({ handleModalClose, handleCreateRecurlyAccount }) => {
  const recurly = useRecurly()
  const formRef = useRef<any>()
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    number: '',
  })
  const [errors, setErrors] = useState<FormErrors>({})
  const [recurlyInputsState, setRecurlyInputsState] = useState<FormErrors>({})
  const [hasRecurlyProfile] = useAtom(hasRecurlyProfileAtom)
  const { updateBillingInfo, updateBillingInfoLoading } = useMutationsHook()
  const [paypalButtonDisabled, setPaypalButtonDisabled] = useState(false)
  const [isPaypalChecked, setIsPaypalChecked] = useState(false)
  const purchaseName = isPaypalChecked ? 'Purchase With PayPal' : 'Purchase'

  const validateForm = (values: FormData) => {
    const errors: FormErrors = {}

    if (!values.firstName.trim()) {
      errors.firstName = 'First Name is required'
    }

    if (!values.lastName.trim()) {
      errors.lastName = 'Last Name is required'
    }

    if (!values.address1.trim()) {
      errors.address1 = 'Address 1 is required'
    }

    if (!values.city.trim()) {
      errors.city = 'City is required'
    }

    if (!values.state.trim()) {
      errors.state = 'State is required'
    }

    if (!values.zipCode.trim()) {
      errors.zipCode = 'Zip code is required'
    }

    return errors
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const updatedForm = { ...formData }
    updatedForm[e.target.name] = e.target.value
    setFormData(updatedForm)
  }

  const handleRecurlyInputChange = (e: IndividualElementChangeEvent, field: string) => {
    const errors = { ...recurlyInputsState }

    if (e.empty) {
      errors[field] = `${field} is empty`
    } else if (!e.empty && !e.valid) {
      errors[field] = `${field} is invalid`
    } else {
      delete errors[field]
    }
    setRecurlyInputsState(errors)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (!hasRecurlyProfile && isPaypalChecked) {
      payWithPayPal()
      return
    }
    recurly.token(formRef.current, (err, token) => {
      const errors = validateForm(formData)
      setErrors({ ...errors, ...recurlyInputsState })
      if (err || Object.keys(errors).length !== 0) {
        console.log('[error]', err)
      } else if (!hasRecurlyProfile && handleCreateRecurlyAccount && !isPaypalChecked) {
        handleCreateRecurlyAccount(token.id, formData)
      } else {
        updateBillingInfo(
          { token: token.id },
          {
            onSuccess: () => {
              handleModalClose()
            },
          },
        )
      }
    })
  }

  const payWithPayPal = () => {
    const paypal = recurly.PayPal({ display: { displayName: 'audigolabs-store' } })
    paypal.start()
    setPaypalButtonDisabled(true)
    paypal.on('cancel', () => {
      setPaypalButtonDisabled(false)
    })

    paypal.on('error', (err: { code: number; message: string; cause: string }) => {
      console.error('Error with PayPal:', err)
    })
    paypal.on('token', (token: { id: string }) => {
      if (!hasRecurlyProfile && handleCreateRecurlyAccount) {
        handleCreateRecurlyAccount(token.id)
      }
    })
  }

  return (
    <form data-container="recurly-form" onSubmit={(e) => handleSubmit(e)} ref={formRef}>
      {!hasRecurlyProfile && (
        <div className={!isPaypalChecked ? 'radio-container active-bar' : 'radio-container'}>
          <input
            type="radio"
            id="credit_card"
            name="payment_method"
            checked={!isPaypalChecked}
            onChange={() => setIsPaypalChecked(false)}
          />
          <label htmlFor="credit_card">Credit Card</label>
        </div>
      )}
      <div className={isPaypalChecked ? 'paypal-pay' : 'credit-card'}>
        <label htmlFor="card_number">
          Credit Card Number
          <CardNumberElement
            onChange={(e) => handleRecurlyInputChange(e, 'number')}
            format={true}
            className="form-input"
            id="card_number"
            style={{ fontColor: '#fff', fontSize: '20px', placeholder: { content: 'Card number' } }}
          />
          {errors.number && <small className="text-error">{errors.number}</small>}
        </label>
        <label htmlFor="cvv">
          Security Code
          <CardCvvElement
            onChange={(e) => handleRecurlyInputChange(e, 'cvv')}
            id="cvv"
            style={{ fontColor: '#fff', fontSize: '20px', placeholder: { content: 'CVV' } }}
          />
          {errors.cvv && <small className="text-error">{errors.cvv}</small>}
        </label>
        <label htmlFor="month">
          Exp. Month
          <CardMonthElement
            onChange={(e) => handleRecurlyInputChange(e, 'month')}
            id="month"
            style={{ fontColor: '#fff', fontSize: '20px', placeholder: { content: 'MM' } }}
          />
          {errors.month && <small className="text-error">{errors.month}</small>}
        </label>
        <label htmlFor="year">
          Exp. Year
          <CardYearElement
            onChange={(e) => handleRecurlyInputChange(e, 'year')}
            id="year"
            style={{ fontColor: '#fff', fontSize: '20px', placeholder: { content: 'YY' } }}
          />
          {errors.year && <small className="text-error">{errors.year}</small>}
        </label>
      </div>
      {!isPaypalChecked && (
        <>
          <input type="hidden" data-recurly="country" value={formData.country} />
          <label htmlFor="first_name">
            First Name
            <input
              id="first_name"
              onChange={handleChange}
              name="firstName"
              data-recurly="first_name"
              placeholder="First Name"
              aria-invalid={!!errors.firstName}
              value={formData.firstName}
            />
            {errors.firstName && <small className="text-error">{errors.firstName}</small>}
          </label>
          <label htmlFor="last_name">
            Last Name
            <input
              id="last-name"
              name="lastName"
              data-recurly="last_name"
              onChange={handleChange}
              placeholder="Last Name"
              aria-invalid={!!errors.lastName}
              value={formData.lastName}
            />
            {errors.lastName && <small className="text-error">{errors.lastName}</small>}
          </label>
          <label htmlFor="address1">
            Address Line 1
            <input
              id="address1"
              name="address1"
              data-recurly="address1"
              placeholder="Main Address"
              onChange={handleChange}
              aria-invalid={!!errors.address1}
              value={formData.address1}
            />
            {errors.address1 && <small className="text-error">{errors.address1}</small>}
          </label>
          <label htmlFor="address2">
            Address Line 2
            <input
              id="address2"
              name="address2"
              data-recurly="address2"
              placeholder="Optional"
              onChange={handleChange}
              value={formData.address2}
            />
          </label>
          <label htmlFor="city">
            City
            <input
              id="city"
              name="city"
              data-recurly="city"
              placeholder="eg. Chicago"
              onChange={handleChange}
              aria-invalid={!!errors.city}
              value={formData.city}
            />
            {errors.city && <small className="text-error">{errors.city}</small>}
          </label>
          <label htmlFor="state">
            State
            <select
              id="state"
              name="state"
              data-recurly="state"
              className={clsx({ empty: !formData.state })}
              placeholder="eg. Chicago"
              aria-invalid={!!errors.state}
              onChange={handleChange}
              value={formData.state}
            >
              <option value="" disabled selected>
                Select state
              </option>
              {STATES.map((el, i) => (
                <option key={i} value={el.abbreviation}>
                  {el.name}
                </option>
              ))}
            </select>
            {errors.state && <small className="text-error">{errors.state}</small>}
          </label>
          <label htmlFor="postal_code">
            Zip Code
            <input
              id="postal_code"
              name="zipCode"
              data-recurly="postal_code"
              placeholder="eg. 94110"
              aria-invalid={!!errors.zipCode}
              onChange={handleChange}
              value={formData.zipCode}
            />
            {errors.zipCode && <small className="text-error">{errors.zipCode}</small>}
          </label>
        </>
      )}
      {!hasRecurlyProfile && (
        <div className={isPaypalChecked ? 'radio-container active-bar' : 'radio-container'}>
          <input
            type="radio"
            id="paypal"
            name="payment_method"
            checked={isPaypalChecked}
            onChange={() => setIsPaypalChecked(true)}
          />
          <label htmlFor="paypal">PayPal</label>
        </div>
      )}
      <div>
        {updateBillingInfoLoading ? (
          <Loader />
        ) : (
          <button disabled={paypalButtonDisabled} type="submit">
            {hasRecurlyProfile ? 'Save Changes' : purchaseName}
          </button>
        )}
      </div>
    </form>
  )
}
export default RecurlyForm
