import { IPlan } from '../../interfaces'
import { FC } from 'react'
import { Link } from 'react-router-dom'

interface Props extends IPlan {}

const PlanInfo: FC<Props> = ({ name, description, detailsList }) => {
  return (
    <div data-container="plan-info">
      <p>Current Plan</p>
      <h4>{name}</h4>
      <span>{description}</span>
      <aside>
        <nav>
          <ul>
            {detailsList.map((value, i) => (
              <li key={i}>{value}</li>
            ))}
          </ul>
        </nav>
      </aside>
      <Link to="/subscription" role="button" className="outline">
        Change Plan
      </Link>
    </div>
  )
}

export default PlanInfo
