import './card.scss'
import { CardI } from '../subscription'
import clsx from 'clsx'
import { FC } from 'react'

interface Props extends CardI {
  activePlanPrice: number
  handleSubscriptionChange: (data: { planCode: string; price: number }) => void
}

const Card: FC<Props> = ({
  name,
  description,
  detailsList,
  price,
  isPlanActive,
  activePlanPrice,
  handleSubscriptionChange,
  code,
  isPending,
}) => {
  let buttonText
  if (isPlanActive || (!price && !activePlanPrice)) {
    buttonText = 'Your Current Plan'
  } else if (isPending) {
    buttonText = 'Pending plan'
  } else if (!price) {
    buttonText = 'No monthly cost'
  } else if (price > activePlanPrice) {
    buttonText = 'Upgrade'
  } else {
    buttonText = 'Change Plan'
  }
  const buttonDisabled = isPlanActive || isPending || (!activePlanPrice && !price)
  return (
    <article data-container="plan-card">
      <div>
        <h4>{name}</h4>
        <p>{description}</p>
        {price > 0 && <h3>${price} / mo </h3>}
        <aside>
          <nav>
            <ul>
              {detailsList.map((data, i) => (
                <li key={i}>{data}</li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
      <button
        disabled={buttonDisabled}
        onClick={() => handleSubscriptionChange({ planCode: code, price: price })}
        className={clsx({ active: buttonDisabled })}
      >
        {buttonText}
      </button>
    </article>
  )
}

export default Card
