export const bytesToGB = (bytes: number): number => {
  const GB = 1024 * 1024 * 1024
  return bytes / GB
}

export const formatDate = (date: string): string => {
  const d = new Date(date)
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + (d.getFullYear() % 100)
}

export const downloadFile = (blob: Blob, invoiceId: string) => {
  const url = window.URL.createObjectURL(blob)
  const filename = `invoice_${invoiceId}.pdf`
  const anchorElement = document.createElement('a')
  anchorElement.href = url
  anchorElement.download = filename
  anchorElement.click()
  window.URL.revokeObjectURL(url)
}
