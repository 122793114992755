import React from 'react'
import './loader.scss'

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //height: 'calc(100vh - 600px)',
}

const Loader = () => {
  return <div style={styles} className="loader" aria-busy={true} />
}

export default Loader
