import { fetchApi } from '../../../services/fetchApi'
import { downloadFile, formatDate } from '../../../utils/helpers'
import clsx from 'clsx'
import { Line } from 'rc-progress'
import { FC, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useQuery } from 'react-query'

interface Props {
  renewalDate: string
  price: string
  paymentStatus: 'paid' | 'past_due'
  invoiceId?: string
  storage: {
    occupied: number
    total: number
  }
}

const SubscriptionInfo: FC<Props> = ({ renewalDate, price, storage, paymentStatus, invoiceId }) => {
  const newDate = formatDate(renewalDate)
  const percent = (storage.occupied / storage.total) * 100

  const [downloadingPdf, setDownloadingPdf] = useState(false)

  const { refetch } = useQuery('invoice', () => fetchApi(`user/get-invoice-binary?param=${invoiceId}`), {
    onSuccess: (data) => {
      downloadFile(data, invoiceId || '')
    },
    onError: (err) => console.error('Failed to get PDF data:', err),
    onSettled: () => setDownloadingPdf(false),
    enabled: false,
  })

  return (
    <div data-container="subscription-info">
      <article className={clsx({ radius: !storage.total })}>
        <span>Next Billing</span>
        <h4>{newDate}</h4>
        {paymentStatus === 'past_due' ? (
          <p>
            <AlertCircle color="#FDC100" /> Payment Failed
          </p>
        ) : (
          <p>Charging ${price}</p>
        )}
        {invoiceId && (
          <button
            className="outline"
            onClick={() => {
              setDownloadingPdf(true)
              refetch()
            }}
            disabled={downloadingPdf}
          >
            {downloadingPdf ? 'Downloading...' : 'Download Last Invoice'}
          </button>
        )}
      </article>
      {storage.total && (
        <article>
          <span>Storage</span>
          <h4>
            {storage.occupied.toFixed(2)}/{storage.total}GB
          </h4>
          <Line
            percent={percent}
            strokeLinecap={'square'}
            strokeWidth={3}
            trailWidth={3}
            strokeColor="#fff"
            trailColor="#666666"
          />
        </article>
      )}
    </div>
  )
}
export default SubscriptionInfo
