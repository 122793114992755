import { fetchApi } from './fetchApi'
import { ChangeSubscriptionRequestType, CreateRecurlyAccountRequestType, UpdateBillingInfoRequestType } from './types'
import { useMutation } from 'react-query'

export const useMutationsHook = () => {
  const { mutate: updateBillingInfo, isLoading: updateBillingInfoLoading } = useMutation({
    mutationFn: (data: UpdateBillingInfoRequestType) => {
      const apiUrl = 'user/update-billing-info'
      return fetchApi(apiUrl, {
        method: 'POST',
        body: data,
      })
    },
  })

  const { mutate: createRecurlyAccount } = useMutation({
    mutationFn: (data: CreateRecurlyAccountRequestType) => {
      const apiUrl = 'user/create-recurly-account'
      return fetchApi(apiUrl, {
        method: 'POST',
        body: data,
      })
    },
  })

  const { mutate: changeSubscription, isLoading: changeSubscriptionLoading } = useMutation({
    mutationFn: (data: ChangeSubscriptionRequestType) =>
      fetchApi('user/change-subscription', { method: 'POST', body: data }),
  })

  return {
    updateBillingInfo,
    updateBillingInfoLoading,
    createRecurlyAccount,
    changeSubscription,
    changeSubscriptionLoading,
  }
}
