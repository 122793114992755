import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark-icon.svg'
import { Link, useSearchParams } from 'react-router-dom'
import './payment-success.scss'
import { fetchApi } from '../../services/fetchApi'
import { formatDate } from '../../utils/helpers'
import { Loader } from '../index'
import { useQuery } from 'react-query'

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams()

  const subscriptionId = searchParams.get('subscriptionId')
  const { data, isLoading } = useQuery('subscription', () =>
    fetchApi(`user/get-subscription?subscriptionId=${subscriptionId}`),
  )

  if (isLoading) {
    return <Loader />
  }

  const formattedDate = formatDate(data.currentPeriodEndsAt)

  const charge = data.pendingChange ? data.pendingChange.unitAmount : data.unitAmount
  const canceledMessage = `You've canceled your ${data.plan.name} plan. Your ${data.plan.name} plan will stay active until ${formattedDate}. After that your Audigo app will be reset automatically with basic functionality.`
  const changeMessage = data.pendingChange
    ? `You've updated to the ${data.pendingChange.plan.name} plan. Your current plan will stay active until ${formattedDate}. Your next bill of $${charge} will be charged
        on ${formattedDate}. You will receive a confirmation email shortly.`
    : `You've upgraded to the ${data.plan.name} plan. You have been charged $${charge} and your next bill of $${charge} will be charged on ${formattedDate}. You will receive a confirmation email shortly. To activate your new plan immediately, please log out of the Audigo app on your mobile device then log back in. Otherwise the new features will be available in about an hour.`

  const message = data.state === 'canceled' ? canceledMessage : changeMessage

  return (
    <div data-container="payment-success">
      <CheckmarkIcon />
      <p>
        {message}
        <br />
        <br />
        Thank you for being an Audigo customer.
      </p>
      <Link to="/" role="button" className="outline">
        Back to your account
      </Link>
    </div>
  )
}

export default PaymentSuccess
