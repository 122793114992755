import './styles.scss'
import { mailSendAtom, userNameAtom } from '../../services/atoms'
import { fetchApi } from '../../services/fetchApi'
import { useAtom, useSetAtom } from 'jotai'
import { useState } from 'react'
import { useMutation } from 'react-query'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [mailSend, setMailSend] = useAtom(mailSendAtom)
  const setUserName = useSetAtom(userNameAtom)
  const { mutate } = useMutation({
    mutationFn: (email: string) => fetchApi('user/reset-password', { method: 'POST', body: { email } }),
    onSuccess: (res) => {
      if (res.status === 200) {
        setUserName(email)
        setMailSend(true)
      }
    },
    onError: (err) => {
      console.log('ERR', err)
    },
  })

  return (
    <div data-container="forgot-password" className="form-container">
      <h2>Forgot password?</h2>
      {!mailSend ? (
        <div>
          <h5>Enter your email below and we’ll send you a message to reset your password.</h5>
          <form>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            <button
              type="submit"
              disabled={!email}
              onClick={(e) => {
                e.preventDefault()
                mutate(email)
              }}
            >
              Reset Password
            </button>
          </form>
        </div>
      ) : (
        <h5>Please check your email for a password reset link.</h5>
      )}
    </div>
  )
}

export default ForgotPassword
