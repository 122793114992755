import React, { createContext, FC, useState } from 'react'

interface User {
  userId: string
  email: string
}

interface UserContextType {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
}

interface Props {
  children: React.ReactNode
}

const initialState = JSON.parse(localStorage.getItem('user') || '{}')

const UserContext = createContext<UserContextType>({
  user: initialState,
  setUser: () => {
    // react setState callback
  },
})

const UserContextProvider: FC<Props> = (props) => {
  const [user, setUser] = useState<User>(initialState)
  return <UserContext.Provider value={{ user, setUser }}>{props.children}</UserContext.Provider>
}

export { UserContext, UserContextProvider }
