import { Loader, RecurlyForm } from '../../components'
import { fetchApi } from '../../services/fetchApi'
import { BillingInfo, PlanInfo, SubscriptionInfo } from './components'
import { ChevronRight, AlertCircle } from 'react-feather'
import { useQuery } from 'react-query'
import './profile.scss'
import { hasRecurlyProfileAtom } from '../../services/atoms'
import { bytesToGB } from '../../utils/helpers'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
  const navigate = useNavigate()
  const [, setHasRecurlyProfile] = useAtom(hasRecurlyProfileAtom)
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const {
    data: profileInfo,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery('plan', () => fetchApi('user/get-profile-info'), {
    onSuccess: (data) => {
      if (!data.billingInfo) {
        setHasRecurlyProfile(false)
      }
    },
  })

  let detailsList = []
  let storageTotal = 0

  if (profileInfo?.plan) {
    detailsList = profileInfo.plan.customFields
      ?.find((el: { name: string; value: string }) => el.name === 'details')
      .value?.split('^')
    storageTotal = profileInfo.plan.customFields.find(
      (el: { name: string; value: string }) => el.name === 'storage',
    )?.value
  }

  const subscriptionInfo = profileInfo?.subscription
  const billingInfo = profileInfo?.billingInfo

  if (isLoading || isRefetching) {
    return <Loader />
  }

  if (profileInfo.status === 204) {
    return <div data-container="payment-success">{profileInfo.message}</div>
  }

  return (
    <div data-container="profile">
      <PlanInfo {...profileInfo.plan} detailsList={detailsList} />
      <div className="flex flex-column grow">
        {billingInfo?.invoice && billingInfo.invoice.state === 'past_due' && (
          <article className="notification payment-error">
            <AlertCircle color="#FDC100" />
            <div>
              <h4>Something is wrong with your billing information...</h4>
              <p>We were unable to process your last payment. Please update your billing information.</p>
            </div>
          </article>
        )}
        {profileInfo.plan.code === 'basic-subscription' && (
          <article onClick={() => navigate('/subscription')} className="notification">
            <div>
              <h4>Get access to more features by upgrading your plan.</h4>
              <p>See what our Studio and Pro plans could unlock for you.</p>
            </div>
            <ChevronRight />
          </article>
        )}
        {profileInfo.plan.code !== 'basic-subscription' && (
          <SubscriptionInfo
            storage={{ occupied: bytesToGB(subscriptionInfo.occupiedStorage), total: storageTotal }}
            paymentStatus={billingInfo.invoice.state}
            renewalDate={subscriptionInfo.currentPeriodEndsAt}
            price={subscriptionInfo.unitAmount}
            invoiceId={billingInfo?.invoice.id}
          />
        )}
        {billingInfo && (
          <BillingInfo
            handleModalChange={() => {
              setModalOpen(true)
            }}
            last4={billingInfo?.paymentMethod.lastFour}
            cardType={billingInfo?.paymentMethod.cardType}
            paypal={billingInfo?.paymentMethod.billingAgreementId}
          />
        )}
      </div>
      <dialog open={modalOpen}>
        <article>
          <h4>
            Edit billing information
            <a href="#close" aria-label="Close" onClick={() => setModalOpen(false)} className="close" />
          </h4>

          {/* @ts-ignore */}
          <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}>
            {/* @ts-ignore */}
            <Elements>
              <RecurlyForm
                handleModalClose={() => {
                  setModalOpen(false)
                  void refetch()
                }}
              />
            </Elements>
          </RecurlyProvider>
        </article>
      </dialog>
    </div>
  )
}

export default Profile
