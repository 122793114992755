import Card from './card/card'
import './subscription.scss'
import { Loader, RecurlyForm } from '../../components'
import { FormData } from '../../components/recurly-form/recurly-form'
import { UserContext } from '../../context/user-context'
import { hasRecurlyProfileAtom } from '../../services/atoms'
import { fetchApi } from '../../services/fetchApi'
import { useMutationsHook } from '../../services/mutations'
import {
  ChangeSubscriptionRequestType,
  ChangeSubscriptionResponseType,
  CreateRecurlyAccountRequestType,
} from '../../services/types'
import { Elements, RecurlyProvider } from '@recurly/react-recurly'
import { useAtom } from 'jotai'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

export interface CardI {
  name: string
  description: string
  detailsList: string[]
  price: number
  currencies: any
  isPlanActive?: boolean
  isPending?: boolean
  code: string
}

const Subscription = () => {
  // TODO: refactor query: move to queries.ts and create separate hook
  const { data, isLoading, refetch } = useQuery('planList', (): Promise<CardI[]> => fetchApi('user/get-plans'))

  const { updateBillingInfo, createRecurlyAccount, changeSubscription, changeSubscriptionLoading } = useMutationsHook()
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [planInfo, setPlanInfo] = useState<ChangeSubscriptionRequestType>({})
  const [hasRecurlyProfile, setHasRecurlyProfile] = useAtom(hasRecurlyProfileAtom)
  const { user } = useContext(UserContext)

  const handleSubscriptionChange = (plan: ChangeSubscriptionRequestType) => {
    setPlanInfo(plan)
    if (!hasRecurlyProfile) {
      setModalOpen(true)
    } else {
      changeSubscription(plan, {
        onSuccess: (res: { data: ChangeSubscriptionResponseType }) => {
          void refetch()
          navigate(`/payment-success?subscriptionId=${res.data.subscriptionId || res.data.id}`)
        },
      })
    }
  }

  const handleCreateRecurlyAccount = (token: string, data?: FormData) => {
    const recurlyAccountData: CreateRecurlyAccountRequestType = {
      code: user.userId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: user.email,
      shipping_addresses: [],
    }

    if (data) {
      recurlyAccountData.shipping_addresses = [
        {
          firstName: data?.firstName,
          lastName: data?.lastName,
          street1: data?.address1,
          street2: data?.address2,
          city: data?.city,
          country: 'US',
          region: data?.state,
          postal_code: data?.zipCode,
        },
      ]
    }

    createRecurlyAccount(recurlyAccountData, {
      onSuccess: () => {
        setHasRecurlyProfile(true)
        updateBillingInfo(
          { token },
          {
            onSuccess: () =>
              changeSubscription(planInfo, {
                onSuccess: (res: { data: ChangeSubscriptionResponseType }) => {
                  void refetch()
                  navigate(`/payment-success?subscriptionId=${res.data.subscriptionId || res.data.id}`)
                },
              }),
          },
        )
      },
    })
  }

  let activePlanPrice = 0
  if (Array.isArray(data)) {
    activePlanPrice = data?.find((el) => el.isPlanActive)?.currencies[0].unitAmount
    data?.sort((a, b) => parseFloat(a.currencies[0].unitAmount) - parseFloat(b.currencies[0].unitAmount))
  }

  if (isLoading || changeSubscriptionLoading) {
    return <Loader />
  }

  return (
    <div data-container="subscription-container">
      <h4 className="text-center">Select from the options below to adjust your monthly plan:</h4>
      <div className="grid">
        {data?.map((el: CardI, i: number) => (
          <Card
            key={i}
            {...el}
            handleSubscriptionChange={handleSubscriptionChange}
            price={el.currencies[0].unitAmount}
            activePlanPrice={activePlanPrice || 0}
          />
        ))}
      </div>
      <dialog open={modalOpen}>
        <article>
          <h4>
            {hasRecurlyProfile ? 'Edit billing information' : 'Enter Billing Information'}
            <a href="#close" aria-label="Close" onClick={() => setModalOpen(false)} className="close" />
          </h4>
          {/* @ts-ignore */}
          <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}>
            {/* @ts-ignore */}
            <Elements>
              <RecurlyForm
                handleCreateRecurlyAccount={handleCreateRecurlyAccount}
                handleModalClose={() => {
                  setModalOpen(false)
                }}
              />
            </Elements>
          </RecurlyProvider>
        </article>
      </dialog>
    </div>
  )
}

export default Subscription
