export const validatePassword = (password: string): { [key: string]: boolean } => {
  const errors = {
    lengthValid: false,
    uppercaseValid: false,
    lowercaseValid: false,
    numberValid: false,
  }
  const lengthRegex = /^.{8,}$/
  const uppercaseRegex = /[A-Z]/
  const lowercaseRegex = /[a-z]/
  const numberRegex = /\d/

  errors.lengthValid = lengthRegex.test(password)
  errors.uppercaseValid = uppercaseRegex.test(password)
  errors.lowercaseValid = lowercaseRegex.test(password)
  errors.numberValid = numberRegex.test(password)

  return errors
}
